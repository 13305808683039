.hostBanner {
    @include full-width-backdrop($ci_impulse-color);

    @include stack-spacing(default, padding-top);
    @include stack-spacing(default, padding-bottom);

    z-index: 1000;

    a {
        color: $accent-color;
    }

    &-host {
        text-align: center;

        &-label,
        &-logo {
            display: block;
        }

        &-label {

            &::after {
                content: ":";
                margin-right: .5em;
            }

        }

        &-logo {
            line-height: 0;

            svg {
                width: 220px;
                height: auto;
            }

        }

    }

    @include not-on-desktop(){

        &-host {

            &-label {
                margin-left: 15px;
            }

            &-logo {
                @include stack-spacing(small, margin-bottom);

                svg {
                    width: 180px;
                }

            }

        }

    }

    @include only-on-desktop(){

        &-host {
            display: flex;
            align-items: center;

            &-label {
                padding-top: .2em; // Vertical align with logo
            }

        }

    }

}


    //***** Right to left *****//

html[dir="rtl"] {

    .hostBanner {

        &-host {

            &-label {

                &::after {
                    margin-right: 0;
                    margin-left: .5em;
                }

            }

        }

    }

}

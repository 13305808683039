.careerPageHeader {
    display: flex;
    flex-direction: column;

    .navigationLink {
        text-align: center;
    }

    &-topic {
        display: block;

        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;

        font-size: rem(24px);
        line-height: 1.4;
    }

    &-title {
        display: block;

        font-family: $headings_font-family;
        font-weight: $headings_font-weight;
        font-size: rem(50px);
        line-height: 1.35;
        margin-bottom: -.15em;

        hyphens: auto;
    }

    &-cta {
        @include stack-spacing();

        .button {
            @include stack-spacing(0);

            background-color: $ci_impulse-color;
            color: $text-color;

            &:hover {
                background-color:  $text-color;
                color: $white;
            }

            .streamlineIcon {
                margin-left: .3em;
            }

        }

    }

    &-image {
        order: 99;
        margin-top: $page_padding__mobile;

        @include has-full-width();
    }

    @include only-on-mobile(){

        &-title {
            font-size: rem(48px);
        }

    }

    @include not-on-desktop(){

        &-topic {

            &:first-child {
                @include stack-spacing();
            }

        }

    }

    @include only-on-desktop(){

        &-text {
            @include stack-spacing(component);
        }

        &-navigation ~ &-text {
            @include stack-spacing();
        }

        &-navigation ~ &-text &-topic {
            max-width: 40%;
        }

        &-image {
            @include stack-spacing(component);

            position: relative;
            padding-bottom: 45%;

            .heroImage {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                &-figure {
                    background-size: cover !important;
                    height: 100%;

                    &::before {
                        display: none;
                    }

                }

            }

        }

    }

}

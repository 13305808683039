body {

    /* Branding on the banner */
    a#CybotCookiebotDialogPoweredbyCybot,
    div#CybotCookiebotDialogPoweredByText {
      display: none;
    }

    /* Branding on the Privacy trigger */
    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
        display: none;
    }

    #CybotCookiebotDialogHeader {
        display: none;
    }

    div[style^="position: absolute;"] {
        display: none;
    }

    //***** Customisation *****//

    #CybotCookiebotDialog {
        font: inherit;
        color: inherit;
    }

    #CybotCookiebotDialogFooter {

        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
        #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
        #CybotCookiebotDialogBodyButtonDecline,
        .CybotCookiebotDialogBodyButton:not(.CybotCookiebotDialogHide) {
            @extend %button;
            justify-content: center;
        }

    }

    #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
        @extend %link;

        &::after {
            display: none;
        }

    }

    #CybotCookiebotDialogBodyButtonsWrapper {
        column-gap: .75em;

        .CybotCookiebotDialogBodyButton {
            margin-left: 0 !important;
        }

    }

    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
        transition-duration: $base_transition-duration__in;

        .CybotCookiebotDialogBodyLevelButtonSlider {
            transition-duration: inherit;

            &::before {
                transition-duration: inherit;
                transition-property: transform, background-color;
            }

        }

        &:hover input[type=checkbox]:not(:disabled) + .CybotCookiebotDialogBodyLevelButtonSlider {
            opacity: 1;

            &::before {
                background-color: $ui-color__hover;
            }

        }

        input:not([disabled]) + .CybotCookiebotDialogBodyLevelButtonSlider {
            background-color: $ui-background-color !important;
        }

    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) {

        .CybotCookiebotDialogBodyLevelButtonDescription {
            user-select: none;

            &:hover {
                color: $link-color;
            }

        }

        &:hover + .CybotCookiebotDialogBodyLevelButtonSliderWrapper input[type=checkbox]:not(:disabled) + .CybotCookiebotDialogBodyLevelButtonSlider {

            &::before {
                background-color: $ui-color__hover;
            }

        }

    }

    .CybotCookiebotDialogNavItemLink {
        //transform: translateY(.25em);
    }

    .CybotCookiebotDialogNavItem {
        user-select: none;
    }

    #CybotCookiebotDialogNav {

        .CybotCookiebotDialogNavItemLink {
            user-select: none;
            color: inherit;
            border-width: 3px;

            padding-bottom: .5em;

            &:hover {
                color: inherit;
                border-bottom-color: $ui_background-color__hover;
            }

            &.CybotCookiebotDialogActive {
                color: inherit;
                border-bottom-color: $ui_background-color;
                cursor: default;
            }

        }

    }

    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: inherit;
    }

    #CybotCookiebotDialog a,
    #CybotCookiebotDialog button {

        &::after,
        &::before {
            border-color: $text-color !important;
        }

    }

    #CybotCookiebotDialogTabContent .CybotCookiebotDialogDetailBulkConsentCount {
        position: relative;
        top: -2px;
    }

    #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
    #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
    #CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
    #CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
    #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
    #CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogDetailBodyContentTextAbout a {
        @extend %link;
    }

    #CybotCookiebotDialog .CybotExternalLinkArrow {
        display: none;
    }

}

#CookiebotWidget {
    display: none !important;
}

@import "scss/_styles";

.mainSection-title + .teaserCard {
    @include stack-spacing(component);
}

.teaserCard {
        // Show underline of proceed link
    margin-bottom: $base_border-width;
}

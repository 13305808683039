.mainFooter {
    $border-color: rgba($white, .7);

    @include stack-spacing(section);

    @include stack-spacing(section, padding-top);
    @include stack-spacing(component, padding-bottom);

    @include full-width-backdrop($accent-color);

    color: $white;

    position: relative;

    &-description {

        &-title {
            font-size: rem(64px);
            line-height: math.div(72px, 64px);

            margin-top: -.2em !important;
        }

        &-copy {
            @include stack-spacing(component);
            font-weight: $base_font-weight__medium;
        }

    }

    &-sitemap {
        @include stack-spacing(component);

        @include stack-spacing(component, padding-top);
        @include stack-spacing(component, padding-bottom);

        &-title {
            display: block
        }

        &-list {
            list-style: none;
            padding: 0;
        }

        &-item {
            @include stack-spacing(small);
        }

        &-link {
            display: inline-block;

            color: inherit;
            text-decoration-color: transparent;

            &:hover {
                color: $ui-color__hover;
            }

            &:active {
                color: $ui-color__active;
            }

            &[href^="tel:"] {
                color: inherit;
            }

        }

    }

    &-service {
        @include stack-spacing(section);
    }

    &-sitemap + &-service {
        @include stack-spacing(component);
    }

    &-license {
        @include stack-spacing(component);

        @include font-size(smallest);
        @include line-height(smallest);

        text-align: center;

        a {
            color: $white;
            text-decoration-color: transparent;

            &:hover {
                color: $ui-color__hover;
                text-decoration-color: $ui-color__hover;
            }

            &:active {
                color: $ui-color__active;
            }

            &::after {
                display: none;
            }

        }

    }

    &-logo {
        $size: 32px;

        width: $size;
        height: $size;

        a {
            color: inherit;

            &:hover {
                color: $ui-color__hover;
            }

            &:active {
                color: $ui-color__active;
            }

        }

        a,
        span {
            display: block;
            height: $size;
        }

        svg {
            width: 100%;
            height: 100%;
        }

    }

    .topLink {
        position: absolute;
        top: 0; left: 0;

        width: 100%;

        display: flex;
        justify-content: flex-end;

        transform: translateY(-100%);

        @include only-on-mobile(){
            justify-content: flex-start;
        }

    }

    .footerNav,
    .socialChannelsBar {
        flex: 0 1;
    }

    .footerNav {
        flex-grow: 1;

        &-link {
            color: $white;

            &:hover {
                color: $ui-color__hover;
            }

            &:active {
                color: $ui-color__active;
            }

        }

    }

    .socialChannelsBar {

        &-item {
            margin: 0;
        }

    }

    @include only-on-desktop(){

        &-description {
            padding-right: get-columns-indent(6);
        }

            // Has sitemap

        &.has-sitemap &-description {
            display: flex;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            padding-right: 0;

            > * {
                flex: 1;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);

                margin-top: 0;
            }

        }

        &-sitemap {

            flex: 1 0 auto;

            display: flex;

            margin-left: (-.5 * $grid_gutter);
            margin-right: (-.5 * $grid_gutter);

            border: none;

            position: relative;

            &::before { // Borders
                content: "";

                display: block;
                height: 100%;
                z-index: -1;

                position: absolute;
                top: 0;
                left: (.5 * $grid_gutter);
                right: (.5 * $grid_gutter);

                border: 1px $border-color;
                border-style: solid none;
            }

            > * {
                flex: 1;

                padding-left: (.5 * $grid_gutter);
                padding-right: (.5 * $grid_gutter);
            }

            &-section {
                margin-top: 0;
            }

        }

        &-service {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        &-logo {
            height: $button_height;

            display: flex;
            flex-direction: column;
            justify-content: center;

            flex: 0 0 auto;
            margin-right: $grid_gutter;

            & + * {
                flex: 1 0 auto;
            }

        }

        .socialChannelsBar {
            margin-top: 0;

            &-list {
                margin-left: (.5 * $grid_gutter);
            }

        }

    }

    @include not-on-desktop(){

        &-description {

            &-title {
                font-size: rem(40px, $base_font-size__mobile);
            }

        }

        &-sitemap {
            border: 1px $border-color;
            border-style: solid none;

            text-align: center;

            &-section {

                &:not(:first-child) {
                    @include stack-spacing(component);
                }

            }

        }

        &-service {
            @include stack-spacing(component, margin-top);
            @include stack-spacing(component, padding-top);

            border-top: 1px solid $border-color;

            & > * + * {
                @include stack-spacing(component);
            }

        }

        &-logo {
            display: none;
        }

        &-sitemap + &-service {
            border-top: none;
            padding-top: 0;
        }

        .footerNav + .socialChannelsBar {
            @include stack-spacing();
        }

    }

}

html[dir="rtl"] {

    @include only-on-desktop(){

        .mainFooter {

            &-logo {
                margin-right: auto;
                margin-left: $grid_gutter;
            }

        }

    }

}

    // Remove gap

.mainFooter {
    @include layout--removeGapAtTop();
}

    // Widen gap to newsletter panel

@include not-on-desktop(){

    .newsletterPanel + .mainFooter {
        $offset: $stack-spacing__mobile;

        margin-top: $offset;

        &::after { // Fill gap
            content: "";

            display: block;
            width: 100vw; max-width: $page_max-width;
            height: $offset;

            position: absolute;
            top: 0; left: 50%;
            z-index: -1;

            margin-top: -1px;

            transform: translate(-50%, -100%);

            background-color: $backdrop-color__opaque;
        }

    }

}

//***** Variants *****//

@import "variants/_styles.compact";

$button_font-size: $base_font-size__small !default;
$button_line-height: $base_line-height !default;

$button_border-width: $base_border-width;

$button_padding: .7em 1.66em !default;

$button_font-size__large: rem(24px);

$button_font-size__small: $base_font-size__smallest !default;
$button_padding__small: .45em 1.6em !default;

$button_height: get-outer-height(
    $font-size: $button_font-size,
    $line-height: $button_line-height,
    $padding: $button_padding,
    $border: $button_border-width,
);

$button_height__mobile: get-outer-height(
    $font-size: $button_font-size,
    $line-height: $button_line-height,
    $padding: $button_padding,
    $border: $button_border-width,
    $base: $base_font-size__mobile,
);

$button_height__small: get-outer-height(
    $font-size: $button_font-size__small,
    $line-height: $button_line-height,
    $padding: $button_padding__small,
    $border: $button_border-width,
);

$button_height__small__mobile: get-outer-height(
    $font-size: $button_font-size__small,
    $line-height: $button_line-height,
    $padding: $button_padding__small,
    $border: $button_border-width,
    $base: $base_font-size__mobile,
);

$button-states: (

    default: (
        background-color: $ui-background-color,
        color: $white,
        font-size: $button_font-size,
        line-height: $button_line-height,
        font-weight: $base_font-weight__medium,
        display: inline-block,
        padding: (nth($button_padding, 1) + .0em) nth($button_padding, 2) (nth($button_padding, 1) - .0em),
        border: $base_border-width solid transparent,
        border-radius: 0,
        text-align: center,
        transition-property: (color, background, border),
        transition-duration: $base_transition-duration__out,
    ),

    hover: (
        background-color: $ui_background-color__hover,
        color: $ui-color,
        transition-duration: $base_transition-duration__in,
    ),

    focus: (
        outline: none,
    ),

    active: (
        background-color: $ui_background-color__active,
        color: $ui-color,
        position: relative,
        top: 2px,
    )

) !default;

$button-states__disabled: (
    default: (
        background-color: $disabled-color !important,
        color: $white !important,
        border-color: transparent !important,
        top: 0 !important,
    )
) !default;

$button-states__secondary: (

    default: (
        background-color: transparent,
        color: $ui-color,
        border-color: $ui-background-color,
    ),

    hover: (
        background-color: transparent,
        color: $ui-color,
        border-color: $ui-background-color__hover,
    ),

    active: (
        background-color: transparent,
        color: $ui-color,
        border-color: $ui-background-color__active,
    ),

) !default;

$button-states__secondary__disabled: (
    default: (
        background-color: transparent !important,
        color: $disabled-color !important,
        border-color: $disabled-color !important,
    )
) !default;

@import "scss/_helpers";

    // Override default helper to prevent focus state on buttons

@mixin button-style (
    $states: $button-states
){
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    @include state-styles($states, default);

    &:hover {
        text-decoration: inherit;
        @include state-styles($states, hover);
    }

    &:focus {
        text-decoration: inherit;
    }

    &:not(a):focus {
        @include state-styles($states, focus);
    }

    &:active {
        text-decoration: inherit;
        @include state-styles($states, active);
    }

}

@import "scss/_settings";
@import "scss/_styles";

%button {
    @include stack-spacing(small);

    display: inline-flex;
    align-items: center;
    column-gap: .5em;

    svg {
        line-height: 0;
    }

    .streamlineIcon {
        display: block;
        line-height: 0;
        top: 0;

        svg {

            * {
                stroke-width: 1.3px !important;
            }

        }

    }

    &[disabled] {

        * {
            pointer-events: none;
        }

    }

    @include not-on-desktop(){
        min-height: $button_height__mobile;

        & + & {
            margin-left: .75em;
        }

    }

    @include color-themes((
        background-color: secondary,
        color: primary,
    ));

    &:hover {

        @include color-themes((
            background-color: secondary__hover,
            color: primary__hover,
        ));

    }

    .is-active-color &,
    &#{&}.is-active-color,
    .is-active-blue &,
    &#{&}.is-active-blue {
        color: get-theme-color(active-color, secondary__hover);
    }

    .is-impulse-color &,
    &#{&}.is-impulse-color,
    .is-impulse-green &,
    &#{&}.is-impulse-green {
        color: get-theme-color(impulse-color, secondary__hover);
    }

    &.is-proceed {

        svg {
            margin-right: 0;
        }

    }

    &.has-icon[href^="http"]::after {
        display: none;
    }

    &[href^="http"]:has(.icon)::after {
        display: none;
    }

}

%button--large {
    @include stack-spacing();
    font-size: $button_font-size__large;
}

%button--small {
    font-size: $button_font-size__small;
    padding: $button_padding__small;
}

.button {

    &.is-large {
        @extend %button--large;
    }

    &.is-small {
        @extend %button--small;
    }

}

//***** Secondary *****//

%button--secondary {

    @include color-themes((
        background-color: transparent,
        color: secondary,
        border-color: secondary,
    ));

    &:hover {

        @include color-themes((
            background-color: transparent,
            color: secondary__hover,
            border-color: secondary__hover,
        ));

    }

}

.button.is-secondary {
    @extend %button--secondary;
}

//***** Icon only *****//

%button--icon-only {
    width: $button_height;
    height: $button_height; min-height: 0 !important;

    padding: 0;

    white-space: nowrap;
    overflow: hidden;
    text-indent: $button_height;

    position: relative;

    svg {
        position: absolute;
        left: 50%; top: 50%;
        transform: translate(-50%, -50%);

        line-height: 0;
    }

    &.is-small {
        width: $button_height__small;
        height: $button_height__small;

        border-width: (math.div(2, 3) * $button_border-width);

        svg {
            $size: 20px;
            width: $size; height: $size;
        }

    }

    @include not-on-desktop(){
        width: $button_height__mobile;
        height: $button_height__mobile;

        &.is-small {
            width: $button_height__small__mobile;
            height: $button_height__small__mobile;
        }

    }

}

%button--icon-only--small {
    width: $button_height__small;
    height: $button_height__small;

    border-width: (math.div(2, 3) * $button_border-width);

    svg {
        $size: 20px;
        width: $size; height: $size;
    }

    @include not-on-desktop(){
        width: $button_height__small__mobile;
        height: $button_height__small__mobile;
    }

}

.button.is-icon-only {
    @extend %button--icon-only;

    .streamlineIcon {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

}

.button.is-icon-only.is-small {
    @extend %button--icon-only--small;
}

%button.is-text-only {
    padding: 0;

    font-size: 1rem;
    line-height: $base_line-height;
    min-height: 0;

    background-color: transparent;
    color: $ui-color;
    border: none;

    display: inline-block;
    white-space: nowrap;

    position: relative;

    &.is-proceed svg {
        margin-right: 0;
    }

    &::before { // Line
        content: "";

        display: block;
        width: 100%; height: 0;

        position: absolute;
        bottom: (-1 * $base_border-width); left: 0;
        z-index: -1;

        transform: scaleX(1);
        transform-origin: bottom left;

        border-bottom: $base_border-width solid $ui-color__hover;

        transition-duration: inherit;
        transition-property: transform;

        pointer-events: none;
    }

    &:hover {
        color: $ui-color;

        &::before { // Line
            transform: scaleX(0);
            transform-origin: bottom right;
        }

    }

}

a.button:focus {
    outline: none;
}

html[dir="rtl"] {

    %button.is-proceed {

        svg {
            transform: scaleX(-1);
        }

    }

}

.pageHeader {
    $modifier: "is-large-billboard";
    $image-ratio: 1;
    $image-ratio__desktop:  math.div(13, 6);

    &.#{$modifier} {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0;
        padding-top: $page_padding__mobile;
        @include stack-spacing(default, padding-bottom);

        text-align: center;

        position: relative;

        @include color-themes((
            color: secondary,
        ));

        @include full-width-backdrop();

        &::before {

            @include color-themes((
                background-color: primary,
            ));

        }

        .heroImage {
            position: relative;

            &-figure[style*="background-image"] {
                display: block;

                &::before {
                    padding-bottom: (math.div(1, $image-ratio) * 100%);
                }
            }

        }

        &.is-active-color,
        &.is-calming-color,
        &.is-active-color,
        &.is-calming-color,
        &.is-active-blue,
        &.is-calming-green {

            .button {
                color: $white;
                background-color: $ci_leading-color;

                &:hover {
                    background-color: $white;
                    color: $ci_leading-color;
                }
            }

        }

        &.is-leading-color,
        &.is-structure-color,
        &.is-leading-blue,
        &.is-structure-silver {

            .button {
                background-color: $ci_impulse-color;

                &:hover {
                    background-color: $white;
                    color: $text-color;
                }
            }

        }

    }

    &.#{$modifier} & {

        &-image,
        &-video,
        &-topic,
        &-hgroup,
        &-title {
            display: block;
            padding: 0;
            width: 100%;
        }

        &-image,
        &-video {
            order: unset;

            .heroImage,
            .video {
                z-index: 1;
            }

        }

        &-video {
            position: relative;

            &::after { // Spacer
                content: "";

                display: block;
                width: 100%; height: 0;
                padding-bottom: (math.div(1, $image-ratio) * 100%);
            }

            .video {
                position: absolute;
                top: 0; left: 50%;
                transform: translateX(-50%);

                height: auto; width: 100%;
                max-width: none;

                @supports (object-fit: cover) {
                    height: 100.25%; // QUICKFIX: +.5% to prevent white border below video
                    object-fit: cover;
                }

            }

        }

        &-topic {
            position: absolute;
            top: $page_padding__mobile;
            left: 0;
            z-index: 2;

            transform: translateY(-50%);
            color: $white;
        }

        &-hgroup {
            position: static;
            z-index: 2;
        }

        &-title {
            $font-size: get-heading-font-size(1);
            $line-height: get-heading-line-height(1);

            margin: (-.5 * $font-size * $line-height) 0 0;

            color: $white;
        }

        &-subtitle {
            margin-bottom: 0;
        }

        &-cta {
            @include stack-spacing();

            .button {
                margin-top: 0;
            }

        }

    }

    @include only-on-desktop(){

        &.#{$modifier} {
            @include stack-spacing(section, padding-top);
            @include stack-spacing(section, padding-bottom);

            .heroImage-figure[style*="background-image"] {

                &::before {
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

        }

        &.#{$modifier} & {

            &-topic {
                @include stack-spacing(section, top);
                @include heading-style(2, $headings_styles, font-size, line-height);
            }

            &-hgroup {
                width: get-column-width( math.div(10, 12));
            }

            &-title {
                $title_max-font-size: px(get-heading-font-size(1));
                $line-height: get-heading-line-height(1);

                $offset: (-.5 * $line-height * $title_max-font-size);

                margin-top: clamp(
                    $offset,
                    #{(math.div($offset, $page-max-width) * 100vw)},
                    (-.5 * 1rem * $line-height)
                );

            }

            &-subtitle {
                $subtitle_max-font-size: px($font-size__largest);

                width: get-column-width(math.div(8, 10));
                margin-left: auto;
                margin-right: auto;

                font-size: (math.div($subtitle-max-font-size, $page-max-width) * 100vw);
                font-size: clamp(1rem, #{(math.div($subtitle-max-font-size, $page-max-width) * 100vw)}, $subtitle_max-font-size);

                font-weight: 500;
            }

            &-image,
            &-video {

                &::after { // Spacer
                    padding-bottom: (math.div(1, $image-ratio__desktop) * 100%);
                }

            }

            &-cta {

                .button {
                    @include stack-spacing(small);
                }

            }

        }

    }

}

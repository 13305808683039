.pageHeader {
    $avatar_size: 80px;
    $avatar_size__mobile: 58px;

    $metadata_seperator_height: 2rem;

    $metadata_font-size: $base_font-size__small;

    @include stack-spacing(section);

    .figure,
    .video {
        margin-top: 0;
    }

    &-topic {
        display: block;

        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;

        font-size: $base_font-size__large;
        line-height: 1.4;
    }

    &-navigation {
        margin-top: ($stack-spacing - $stack-spacing__section);
        margin-top: calc(var(--sp) - var(--sp-section));

        @include stack-spacing(component, margin-bottom);

        .navigationLink {
            margin-top: 0;
            text-align: center;
        }

    }

    &-title {
        @extend h1;
        display: block;

        @include stack-spacing(small);
    }

    &-subtitle {
        @include heading-style(2, $headings_styles, font-size, line-height);
        font-weight: $base_font-weight;

        @include stack-spacing(small);
    }

    &-cta {

        .navigationLink {
            margin-top: 0;
        }

    }

    &-leadtext {

        > *:first-child {
            margin-top: 0;
        }

    }

    &-metadata {
        $gutter: $grid_gutter;

        @extend .is-uppercase;

        font-size: $metadata_font-size;
        font-weight: $base_font-weight__medium;

        &-list {
            display: flex;
            align-items: center;

            margin: 0;
        }

        &-key {
            width: 0;

                // Hide Keys
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;

            position: static;

            &:not(:first-child) {
                width: $gutter;
            }

        }

        &-value {
            margin: 0;

            position: relative;

            &:not(:last-child) {

                &::after { // Line between values
                    content: "";

                    display: inline-block;
                    width: 0; height: $metadata_seperator_height;

                    position: absolute;
                    top: 50%; right: (-.5 * $gutter);

                    transform: translateY(-50%);

                    margin-left: -1px;

                    vertical-align: middle;

                    border-right: 1px solid $gray;
                }

            }

            &.is-author {

                a {
                    text-decoration: none;
                }

                img {
                    display: inline-block;
                    width: $avatar_size__mobile;
                    height: $avatar_size__mobile;

                    margin: 0;
                    vertical-align: middle;

                    border-radius: 50%;
                }

            }

        }

    }

    @include not-on-desktop(){
        margin-top: 0;

        &-image,
        &-video {
            margin-left: (-1 * $page_padding__mobile);
            margin-right: (-1 * $page_padding__mobile);
        }

        &-topic {
            font-size: 1rem;
        }

        &-hgroup {
            margin-top: $stack-spacing__mobile;
        }

        &-subtitle {
            @include heading-style(2, $headings_styles__mobile, font-size, line-height);
        }

        &-metadata {
            $image_gutter: .8em;

            margin-top: $stack-spacing__component;

            &-list {
                position: relative;
            }

            &-key {
            }

            &-value {

                img {
                    position: absolute;
                    left: 0; top: 50%;

                    transform: translateY(-50%);
                }

            }

        }

        .intrapageLink {
            display: none;
        }

    }

    @include only-on-desktop(){
        $title_max-font-size: 100px;
        $subtitle_max-font-size: px(get-heading-font-size(2));

        @include grid-row();

        flex-wrap: wrap;
        justify-content: flex-start;

        position: relative;

        &-hgroup,
        &-image,
        &-video,
        &-metadata {
            @include grid-column();

            flex: 0 0 auto;
            width: 50%;
        }

        &-image,
        &-video {
            order: 1;
        }

        &-hgroup {
            flex: 0 1 auto;
            width: (math.div(6, 12) * 100%);

            position: relative;
            z-index: 1;

                // Prevent overlapping meta data
            padding-bottom: ($stack-spacing__component + ($metadata_font-size * $base_line-height));
        }

        &-title {
            width: get-column-width(math.div(9, 6));
            font-size: (math.div(100px, $page-max-width) * 100vw);
            font-size: clamp(1rem, #{(math.div($title-max-font-size, $page-max-width) * 100vw)}, $title_max-font-size);
        }

        &-subtitle {
            font-size: (math.div($subtitle-max-font-size, $page-max-width) * 100vw);
            font-size: clamp(1rem, #{(math.div($subtitle-max-font-size, $page-max-width) * 100vw)}, $subtitle_max-font-size);
        }

        @media (min-width: $page_max-width){
            // Fallback for browsers that do not support clamp();

            &-title {
                font-size: $title_max-font-size;
            }

            &-subtitle {
                font-size: $subtitle_max-font-size;
            }

        }

        &-metadata {
            $gutter: $grid_gutter;

            position: absolute;
            left: 0;
            z-index: 1;

                // Align with the image
            bottom: .4em;
            transform: translateY(50%);

            &-value {

                &.is-author {

                    img {
                        width: $avatar_size;
                        height: $avatar_size;

                        margin-right: (math.div(1, 3) * $gutter);
                        margin-top: -.3em;
                    }

                }

            }

        }

    }

}

    //Legacy: Use topic instead
.pageHeader-eyebrow {
    @extend .pageHeader-topic;
}

    //***** Variants *****//

@import "variants/_billboard";
@import "variants/_cut-out";
@import "variants/_large";
@import "variants/_large-billboard";
@import "variants/_simple";
@import "variants/_text-only";
@import "variants/_wallpaper";

    //***** Right to left *****//

html[dir="rtl"] {

    .pageHeader-metadata-value:not(:last-child)::after {
        right: auto;
        left: (-.5 * $grid_gutter)
    }

    @include only-on-desktop(){

        .pageHeader-metadata {
            left: auto;
            right: 0;
        }

    }

}

.eventPageHeader {
    $color: $white;
    $backdrop-color: $ci_leading-color;

    color: $color;

    padding-top: $page_padding__mobile;
    padding-bottom: $page_padding__mobile;

    @include full-width-backdrop($backdrop-color);

    &-navigation {
        display: flex;
        justify-content: center;

        .navigationLink {
            margin-top: 0;
        }

    }

    &-figure {
        position: relative;
    }

    &-navigation + &-figure {
        @include stack-spacing();
    }

    &-badge {
        position: absolute;
        top: 0;
        left: 0;

        @include stack-spacing();
    }

    &-text {
        @include stack-spacing();
    }

    &-topic {
        display: block;

        font-weight: $base_font-weight__bold;
        text-transform: uppercase;
        letter-spacing: .025em;

        font-size: $base_font-size__large;
        line-height: 1.4;
    }

    &-title {
        @extend h1;
        display: block;

        @include stack-spacing(small);

        margin-bottom: -.2em;
    }

    &-metadata {
        display: block;

        @include stack-spacing(component);
        margin-top: calc(var(--sp-component) -  var(--sp));

        border-collapse: collapse;

        @extend .is-uppercase;

        @include font-size(small);
        @include line-height(small);
        font-weight: $base_font-weight__medium;

        &-items {
            margin: 0;
        }

        &-item {
            display: flex;
            align-items: flex-start;

            width: 100%;

            @include stack-spacing(small);
        }

        &-key {
            display: block;
            white-space: nowrap;

            &::before { // Icon
                content: "";

                display: inline-block;
                width: $icon_size; height: $icon_size;

                margin: (-.5 *$icon_size) .5em (-.5 *$icon_size) 0;
                vertical-align: middle;

                position: relative;
                top: -.1em;

                background: center center no-repeat;
            }

            @each $key in (datetime, duration, language, venue, booth) {

                &.is-#{$key}::before {
                    $icon_data: get-icon-data-url($key, $color);
                    background-image: url($icon_data);
                }

            }

            &::after {
                content: ":";
                margin-right: .5em;
            }

            &.is-datetime,
            &.is-venue,
            &.is-booth {

                span {
                    @extend %visually-hidden;
                }

                &::after {
                    display: none;
                }

            }

        }

        &-value {
            display: block;
        }

        .datetime {

            &-cities {
                font-weight: $font-weight;
                display: block;

                @include font-size(smallest);
            }

        }

        .venue {

            &-address {
                font-weight: $font-weight;
                display: block;

                @include font-size(smallest);
            }

        }

    }

    &-leadtext {
        @include stack-spacing(component);

        h2 {
            @extend h4;
        }

        ul {
            @extend .ul;
            margin-top: 0;

            li {
                @extend .ul-li;

                &::before {
                    background-color: $color;
                }

            }

        }

        h2 + ul {
            @include stack-spacing(small);
        }

    }

    &-cta {
        @include stack-spacing(component);

        .button {
            margin-top: 0;
            background-color: $ci_impulse-color;

            &:hover {
                background-color: $white;
                color: $text-color;
            }

        }

        .icon.is-calendar-add {
            margin-left: .6em;
        }

    }

    @include only-on-mobile(){

        &-cta {

            .button {
                width: 100%;
            }

        }

    }

    @include not-on-desktop(){

        &-badge {
            @include stack-spacing(small);
        }

    }

    @include only-on-desktop(){
        $columns_span: 8;

        @include stack-spacing(section, padding-top);
        @include stack-spacing(section, padding-bottom);

        position: relative;

        &-navigation {
            @include stack-spacing();

            position: absolute;
            top: 0;

            width: 100%;
        }

        &-figure {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;

            width: get-column-width(math.div(4, 12));
            margin-top: 0 !important;
        }

        &-badge {
            left: 1px;
        }

        &-image,
        &-video {
            $inset: $page_padding;
            $inset__max: .5 * ($page_max-width - $page_content_max-width + $grid_gutter);

            height: 100%;

            width: calc(100% + (#{$inset}));
            max-width: calc(100% + #{$inset__max});

            position: relative; left: 1px; // Prevent flicking background

            @include only-on-small-desktop(){
                width: calc(100% + (#{.5 * $inset}));
            }

        }

        .heroImage {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &-figure {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                &::before {
                    padding-bottom: 0;
                    height: 100%;
                }

            }

        }

        .video {
            margin-top: 0;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            height: 100%;

            object-fit: cover;
        }

        &-text {
            margin-top: 0;
            width: get-column-width(math.div($columns_span, 12));
        }

        &-metadata {

            &-items {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: $grid_gutter;
            }

        }

    }

}

html[dir="rtl"] {

    .eventPageHeader {

        &-badge {
            left: auto;
            right: 0;
        }

        &-metadata {

            &-key {

                &::before { // Icon
                    margin-right: 0;
                    margin-left: .5em;
                }

            }

        }

        @include only-on-desktop(){

            &-figure {
                right: auto;
                left: 0;
            }

            &-image,
            &-video {
                left: auto;
                right: 1px; // Prevent flicking background
            }

        }

    }

}


@import "scss/_styles";
@import "vendor/_lite-yt-embed";

.videoPlayer {

    lite-youtube {
        position: absolute;
        top: 0; left: 0;

        width: 100%;
        max-width: none;
        height: 100%;


        outline: 1px solid red;
    }

}

$ci_leading-color: #001E46;
$ci_active-color: #00B3FF;

$ci_structure-color: rgba(55, 70, 80, 1);
$darkgray: rgba(55, 70, 80, .77);
$gray: rgba(55, 70, 80, .44);
$lightgray: rgba(55, 70, 80, .09);
$palegray: rgba(55, 70, 80, .03);
$off-white: #FAFBFC;

$darkgray__opaque: #647078;
$gray__opaque: #A4ABB0;
$lightgray__opaque: #E8EBED;
$palegray__opaque: #F4F6F7;

$ci_impulse-color: #2FF3E0;
$ci_calming-color: #5DAE95;
$midnight-blue: #00122B;

// By purpose

$white: $off-white !default;
$black: $midnight-blue !default;
$text-color: $ci_leading-color !default;
$page-color__transparent: rgba(20, 70, 80, .02) !default;

$accent-color: $ci_leading-color !default;
$accent-color__darken: $accent-color !default;

$ui-color: $accent-color !default;
$ui-color__hover: $ci_impulse-color !default;
$ui-color__active: $ci_impulse-color !default;

$ui_background-color: $accent-color !default;
$ui_background-color__hover: $ci_impulse-color !default;
$ui_background-color__active: $ci_impulse-color !default;

$disabled-color: rgba(55, 70, 80, .33) !default;

$minor-color: $darkgray !default;

$information-color: $ci_active-color !default;
$success-color: $ci_calming-color !default;
$warning-color: #E6932B !default;
$error-color: #B00020 !default;

$notification-color: $warning-color;

$backdrop-color: $lightgray !default;
$backdrop-color__opaque: $lightgray__opaque !default;

$viewport_background-color: rgba($backdrop-color__opaque, .5);

$color-themes: (

    leading-color: (
        text-color: $white,
        accent-color: $ci_impulse-color,
        primary: $ci_leading-color,
        secondary: $white,
        primary__hover: $text-color,
        secondary__hover: $ci_impulse-color,
     ),

    active-color: (
        text-color: $midnight-blue,
        accent-color: $midnight-blue,
        primary: $ci_active-color,
        secondary: $midnight-blue,
        primary__hover: $text-color,
        secondary__hover: $white,
    ),

    calming-color: (
        text-color: $white,
        accent-color: $white,
        primary: $ci_calming-color,
        secondary: $white,
        primary__hover: $white,
        secondary__hover: $ci_leading-color,
    ),

    structure-color: (
        text-color: $white,
        accent-color: $ci_impulse-color,
        primary: $ci_structure-color,
        secondary: $white,
        primary__hover: $text-color,
        secondary__hover: $ci_impulse-color,
    ),

    impulse-color: (
        text-color: $text-color,
        accent-color: $text-color,
        primary: $ci_impulse-color,
        secondary: $text-color,
        primary__hover: $text-color,
        secondary__hover: $white,
    ),

) !default;

@import "scss/_helpers";
@import "scss/_settings";
@import "scss/_styles";

@import "_color.legacy";

*::selection {
    background: $ci_impulse-color;
    color: $text-color !important;

    .is-impulse-color &,
    .is-impulse-green & {
        background: $white;
    }

}

    //** Documentation only **//

.doc-themes-item-sample {

    @include color-themes((
        background-color: primary,
        color: text-color,
    ));

}

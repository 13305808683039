.blogBanner {
    $logo_width: 120px;

    $height: 48px;
    $height__mobile: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    height: $height;

    color: $white;
    @include full-width-backdrop($ci_structure-color);

    &-logo {
        display: flex;
        width: $logo_width;
        margin-top: -0.15em;

        color: inherit !important;

        .logo {
            width: 100%;
            height: 100%;

            display: flex;
        }

    }

    &-claim {
        display: block;
        @include font-size(smallest);
        line-height: 1.1;
        font-weight: $base_font-weight__medium;
    }

    &-logo + &-claim {
        display: flex;
        align-items: center;

        &::before {
            content: "–";
            margin-left: .3em;
            margin-right: .3em;
        }

    }

    @include only-on-mobile(){
        height: $height__mobile;

        &-logo {
            display: block;
            width: math.div($logo_width * $height__mobile, $height);
        }

    }

}

$mainHeader_has-collapsing-hostbanner: false;

// @import "scss/_helpers";
// @import "scss/_settings";
@import "scss/_styles";

.mainHeader {
    $mainNav-item_line-height: (math.div($base-font-size * map-get($mainNav-item-style, font-size), 1rem) * map-get($mainNav_item_style, line-height));

    $flyout_gap: (.5 * ($mainHeader_height - $mainNav-item_line-height));

    .serviceNav {
        @include has-limited-width();

        z-index: 1;

        &::before {
            background-color: $page-color;
        }

    }

    .mainNav {

        @include only-on-desktop(){

                // Set origin of mega menu relative to mainHeader

            &-list.is-level-1 {
                position: static;
            }

                // Fill gap to mega menu

            &-item.is-mega-menu .mainNav {

                &-list.is-level-2 {
                    border-top: $flyout_gap solid transparent;
                    margin-top: (-1 * $flyout_gap);
                }

            }

        }

    }

    @include only-on-desktop(){

        &-search {

            .searchPanel {
                transition: inherit;

                    // Fill gap to nav bar
                border-top: $flyout_gap solid transparent;
                margin-top: (-1 * $flyout_gap);

                &::before { // Backdrop (is set in component)
                    display: none;
                }

                &-controls {
                    display: block;
                }

            }

        }

    }

}

    // Hide duplicated items on desktops

.mainHeader {

    @include only-on-desktop(){

        .mainNav:not(.is-locale){

            .mainNav-item.is-locale {
                display: none;
            }

        }

        .mainNav:not(.is-language){

            .mainNav-item.is-language {
                display: none;
            }

        }

        .mainNav:not(.is-search){

            .mainNav-item.is-search {
                display: none;
            }

        }

        .mainNav-item.is-service-item {
            display: none;
        }

    }

}

    //***** Push content downwards for intrapage links *****//

body.has-hostBanner {
    --mainHeader-height: #{$mainHeader_height + $mainHeader_host_height};

    @include not-on-desktop(){
        --mainHeader-height: #{$mainHeader_height__mobile + $mainHeader_host_height__mobile};
    }

}

@mixin mainHeader-push-down(
    $stack-spacing: null,
){

    $offset: var(--mainHeader-height);
    $offset__fallback: $mainHeader_height;

    @if $stack-spacing {
        $offset: calc(#{$offset} + var(--sp-#{$stack-spacing}));

        $stack-spacings: map-get($stack-spacings, default);

        @if map-has-key($stack-spacings, $stack-spacing) {
            $offset__fallback: calc(#{$offset__fallback} + #{map-get($stack-spacings, $stack-spacing)})
        }

    }

    scroll-margin: $offset__fallback;
    scroll-margin: $offset;
}

a[id]:empty {
    display: block;
    @include mainHeader-push-down();
}

.mainSection,
.contentBlock {

    &[id] {
        @include mainHeader-push-down(section);
    }

}

.slideGroup {

    &-item {
        @include mainHeader-push-down();
    }

    @include only-on-mobile(){
        @include stack-spacing(section);
    }

}

    //***** Push content below fixed header with host banner *****//

.is-blogStart,
.is-blogEdition,
.is-blogArticle {

    .mainHeader + .spacer {
        //** Push content below fixed header **//
        padding-top: $mainHeader_height + $mainHeader_host_height;

        @include not-on-desktop(){
            padding-top: $mainHeader_height__mobile + $mainHeader_host_height__mobile;
        }

    }

}

    //***** Push socialShareBar downwards to not colliding with mainHeader *****//

@include only-on-desktop(){
    $offset: rem($mainHeader_height);
    $offset: 1.1 * $offset; // Optically align with section title

    .socialShareBar {
        transform: translateX(-50%) translateY(-1 * $offset) !important;

        &.is-stuck {
            transform: translateX(-50%) translateY(0) !important;
        }

        &::before { // Spacer
            padding-top: ($offset + $stack-spacing__section) !important;
        }

    }

}
